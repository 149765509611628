import { CarouselHome } from "@/components/Carousel/Home"
import { Meta } from "@/components/meta/Meta"
import { getHomePage } from "@/data/get-home-page"
import { ComponentBlocksMultipleImages, ComponentTamdaocHomePage } from "@/gql/generated-types"
import { AkceSection } from "@/modules/home/components/AkceSection"
import { Member } from "@/modules/home/components/Member"
import { Partner } from "@/modules/home/components/Partner"
import { RentalService } from "@/modules/home/components/RentalService"
import { WhyChooseMe } from "@/modules/home/components/WhyChooseMe"
import { useGeneralStyles } from "@/styles/useGeneralStyles"
import { Container, Flex } from "@mantine/core"
import { first, last } from "lodash"
import { useRouter } from "next/router"
import { FC } from "react"

interface Props {
  page: Awaited<ReturnType<typeof getHomePage>>
}

export const HomePage: FC<Props> = ({ page }) => {
  const { classes: cs, cx } = useGeneralStyles()
  const { locale } = useRouter()

  const block = page?.attributes?.block ?? []

  const blockCarousel = block.find(e => e?.__typename === "ComponentSlidesSlidesWith2Cta")
  const blockCategories = block.find(e => e?.__typename === "ComponentBlocksMultipleImages")
  const blockAkce = block.find(e => e?.__typename === "ComponentTamdagroupHomeServices")
  const blockRentalServices = block.find(e => e?.__typename === "ComponentTamdaocHomePage") as ComponentTamdaocHomePage
  const blockMember = block.find(e => e?.__typename === "ComponentTamdagroupMemberCompanies")
  const blockMultipleImages = block.filter(
    e => e?.__typename === "ComponentBlocksMultipleImages",
  ) as ComponentBlocksMultipleImages[]

  return (
    <>
      {/* @ts-ignore */}
      <Meta data={{ canonicalURL: "/", ...page?.attributes?.seo }} />

      {/* @ts-ignore */}
      {blockCarousel && <CarouselHome slides={blockCarousel?.slide} />}
      <Container className={cx(cs.paddingBlock700, cs.paddingTop900)}>
        <RentalService block={blockRentalServices} />
      </Container>
      <Container className={cs.paddingBlock700}>
        <WhyChooseMe data={first(blockMultipleImages)} />
      </Container>

      <Flex className={cs.paddingBlock700}>
        {/* @ts-ignore */}
        <AkceSection items={blockAkce?.services} title={blockAkce?.title ?? "AKCE & PROMOTION"} />
      </Flex>
      <Container className={cs.paddingBlock700}>
        <Partner data={last(blockMultipleImages)} />
      </Container>
      {/* 
      <Container className={cs.paddingBlock700}>
        <News />
      </Container> 
      */}

      <Container className={cs.paddingBlock700}>
        {/* @ts-ignore */}
        <Member data={blockMember} />
      </Container>
    </>
  )
}
