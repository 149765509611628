import { getImageUrl } from "@/data/helpers"
import { UploadFileEntity } from "@/gql/generated-types"
import get from "get-value"
import Head from "next/head"
import { FC, ReactNode } from "react"

interface MetaSEOProps {
  metaTitle: string
  metaDescription?: string
  canonicalURL?: string | null
  keywords?: string | null
  metaImage?: any
}

interface PropsWithChildren {
  children?: ReactNode
}

interface MetaSEO extends PropsWithChildren {
  data: MetaSEOProps
}

export const getSEOAttributes = (
  seoComponent?: MetaSEOProps | null,
  fallbackTitle?: string,
  fallbackDescription?: string,
  fallbackImage?: UploadFileEntity | any,
): MetaSEOProps => {
  return {
    ...(seoComponent ?? {}),
    metaTitle: seoComponent?.metaTitle ?? fallbackTitle ?? "",
    metaDescription: seoComponent?.metaDescription ?? fallbackDescription,
    metaImage: seoComponent?.metaImage ?? fallbackImage,
  }
}

const SITE_NAME = "TAMDA OC"
const DEFAULT_COVER_IMG = "https://d300nzfp1n1jsy.cloudfront.net/import_export_99eba435b9_7d20f0191b.webp"

export const Meta: FC<MetaSEO> = ({ data, children }) => {
  const canonicalURL = get(data, "canonicalURL")
  return (
    <Head>
      <title>{`${get(data, "metaTitle")} - ${SITE_NAME}`}</title>
      <meta name="description" content={get(data, "metaDescription")} />
      <meta name="keywords" content={get(data, "keywords")} />

      <meta property="og:title" content={get(data, "metaTitle")} />
      <meta property="og:description" content={get(data, "metaDescription")} />
      <meta
        property="og:image"
        content={
          get(data, "metaImage.data.attributes.url")
            ? getImageUrl(get(data, "metaImage.data.attributes.url"), get(data, "metaImage.data.attributes.provider"))
            : DEFAULT_COVER_IMG
        }
      />
      {canonicalURL && <meta property="og:url" content={canonicalURL} />}

      <meta name="twitter:title" content={get(data, "metaTitle")} />
      <meta name="twitter:description" content={get(data, "metaDescription")} />
      <meta
        name="twitter:image"
        content={
          get(data, "metaImage.data.attributes.url")
            ? getImageUrl(get(data, "metaImage.data.attributes.url"), get(data, "metaImage.data.attributes.provider"))
            : DEFAULT_COVER_IMG
        }
      />

      {canonicalURL && <link rel="canonical" href={canonicalURL} />}

      {children && children}
    </Head>
  )
}
