import { APP_CONFIG } from "@/config"
import { getHomePage } from "@/data/get-home-page"
import { getWebsiteSettings } from "@/data/get-website-settings"
import { getNavigations } from "@/data/navigations"
import { MainLayout } from "@/layout/MainLayout"
import { HomePage } from "@/modules/home/pages"
import { GetStaticProps, InferGetStaticPropsType } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

export default function Index(props: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <MainLayout navigations={props.navigations} settings={props.websiteSettings}>
      <HomePage page={props.page} />
    </MainLayout>
  )
}

interface Props {
  navigations: Awaited<ReturnType<typeof getNavigations>>
  page: Awaited<ReturnType<typeof getHomePage>>
  websiteSettings: Awaited<any>
}

export const getStaticProps: GetStaticProps<Props> = async ({ locale = "en" }) => {
  const navigations = await getNavigations(locale)
  const page = await getHomePage({ id: APP_CONFIG.HOME_PAGE_ID.find(e => e.locale === locale)!.pageId!, locale })
  if (!page) {
    return { notFound: true }
  }
  const websiteSettings = await getWebsiteSettings()
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["common"])),
      navigations,
      page,
      websiteSettings,
    },
    revalidate: 10,
  }
}
