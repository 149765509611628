import { UploadFileEntityResponse } from "@/gql/generated-types"
import { Box, Button, Container, Grid, createStyles, getStylesRef } from "@mantine/core"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import { FC, useRef, useState } from "react"
import Slider from "react-slick"

const sliderSettings = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  infinity: true,
  touchThreshold: 1000,
}

export interface SlideContent {
  title: string
  description: string
  backgroundImage: Partial<UploadFileEntityResponse>
  icon: Partial<UploadFileEntityResponse>
  callToActionButtonText: string
  callToActionButtonUrl: string
}

interface Props {
  items: SlideContent[]
  title: string
}

const useStyles = createStyles(theme => ({
  wrapper: {
    width: "100%",
    height: 650,
    position: "relative",
    transition: "all .9s ease-in-out",
    overflowX: "hidden",
    [theme.fn.smallerThan("sm")]: {
      height: "100%",
    },
  },
  sliderContainer: {
    "&:not(.default-slide) .slick-current": {
      [`.${getStylesRef("transition")}`]: {
        animation: "bounce-in 400ms",
        animationFillMode: "forwards",
      },
      [`.${getStylesRef("slideItemOverlay")}`]: {
        opacity: 0.95,
        background: "rgba(21, 126, 64, 0.9)",
      },
    },
  },
  slideItemOverlay: {
    ref: getStylesRef("slideItemOverlay"),
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 1,
    borderRadius: 8,
    opacity: 0,
    transition: "all .3s ease-in",
  },
  transition: {
    ref: getStylesRef("transition"),
    zIndex: 2,
    transition: "all .3s ease",
  },
  bgImageOverlay: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    bottom: "0",
    right: "0",
    transition: "all .5s ease-in",
    zIndex: -1,
    "&:after": {
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      bottom: "0",
      right: "0",
      content: '""',
      background: "#000",
      opacity: 0.7,
    },
  },
  current: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  serviceTitle: {
    fontSize: "4.5rem",
    fontWeight: 900,
    color: "#FFFFFF",
    lineHeight: 1.2,

    [theme.fn.smallerThan("sm")]: {
      fontSize: "2.5rem",
    },
  },
  info: {
    fontSize: "1rem",
    color: "#FFF",
    maxWidth: "23.5rem",
    lineHeight: 1.4,

    [theme.fn.smallerThan("sm")]: {
      fontSize: ".875rem",
      lineHeight: 1.2,
      marginBlock: ".5rem",
    },
  },
  callToAction: {
    display: "flex",
    alignItems: "center",
    marginTop: "1.5rem",
  },
  title: {
    color: "#FFF",
    fontSize: "1.25rem",
    textTransform: "uppercase",
  },
  container: {
    height: "100%",
    overflow: "hidden",
    [theme.fn.smallerThan("sm")]: {
      marginTop: "1.2rem",
      marginBottom: "1.2rem",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.fn.smallerThan("sm")]: {
      marginTop: "1.2rem",
      marginBottom: "1.2rem",
    },
  },
  serviceLogo: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  slideItem: {
    width: "263px !important",
    height: "382px",
    cursor: "pointer",
    transition: "transform .3s ease",

    [`&:hover`]: {
      [`.${getStylesRef("transition")}`]: {
        animation: "bounce-in 400ms",
        animationFillMode: "forwards",
      },
      [`.${getStylesRef("slideItemOverlay")}`]: {
        opacity: 0.95,
        background: "rgba(21, 126, 64, 0.9)",
      },
    },
    [theme.fn.smallerThan("sm")]: {
      [`&:not(.default-slide) .slick-current`]: {
        [`.${getStylesRef("transition")}`]: {
          animation: "bounce-in 400ms",
          animationFillMode: "forwards",
        },
        [`.${getStylesRef("slideItemOverlay")}`]: {
          opacity: 0.95,
          background: "rgba(21, 126, 64, 0.9)",
        },
      },
    },
  },
  slideItemImg: {
    position: "relative",
    width: "243px !important",
    height: "342px !important",
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0 0 6px rgba(0,0,0,.3))",
    borderRadius: 8,
    marginTop: "20px",
    justifyContent: "center",
    flexDirection: "column",
  },

  slideItemTitle: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    fontWeight: 900,
    textAlign: "center",
    color: "#FFF",
    textTransform: "uppercase",
  },

  sliderCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.fn.largerThan("sm")]: {
      position: "absolute",
      top: 0,
      left: "38%",
      height: "100%",
    },
    [theme.fn.largerThan("135rem")]: {
      left: "40%",
    },
    [theme.fn.largerThan("160rem")]: {
      left: "42%",
    },
    [theme.fn.largerThan("185rem")]: {
      left: "44%",
    },
  },
}))

export const AkceSection: FC<Props> = props => {
  const [current, setCurrent] = useState(0)
  const [isDefaultSlide, setIsDefaultSlide] = useState(true)

  const data = (props.items ?? []).map(e => {
    return {
      title: e.title,
      content: e.description,
      image: e.backgroundImage.data?.attributes?.url ?? "",
      icon: e.icon.data?.attributes?.url ?? "",
      buttonText: e.callToActionButtonText,
      buttonUrl: e.callToActionButtonUrl,
    }
  })

  const { classes, cx } = useStyles()
  const slides = [...data]
  const sliderRef = useRef<Slider>(null)

  return (
    <div className={classes.wrapper}>
      {data.map(e => (
        <div
          key={e.title}
          className={clsx(classes.bgImageOverlay, e.title === data[current]?.title ? classes.current : classes.hidden)}
        >
          <img
            key={e.title}
            src={`${e.image}`}
            alt={e.title}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </div>
      ))}
      <div className="bg-overlay"></div>
      <Container className={classes.container}>
        <Grid className={classes.row}>
          <Grid.Col span={12} sm={4}>
            <div className={classes.serviceLogo}>
              <Image width={133} height={48} src={"/images/index-4.svg"} alt="" />
            </div>
            <h3 className={classes.serviceTitle}>{data[current]?.title}</h3>
            <p className={classes.info}>{data[current]?.content}</p>

            {data[current]?.buttonText && (
              <div className={classes.callToAction}>
                <div style={{ width: 85 }}>
                  <Image width={56} height={56} src={"/images/index-5.svg"} alt="Cart" />
                </div>
                <div>
                  <Link href={data[current]?.buttonUrl ?? ""}>
                    <Button variant="outline" color="neutral">
                      <span className="button-text">{data[current]?.buttonText}</span>
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </Grid.Col>

          <Grid.Col span={12} sm={8} className={classes.sliderCol}>
            <h5 className={classes.title}>{props?.title}</h5>
            <Box>
              <Slider
                {...sliderSettings}
                // @ts-ignore
                style={{ width: 1080 }}
                ref={sliderRef}
                className={cx(isDefaultSlide ? "default-slide" : "", classes.sliderContainer)}
                afterChange={currentSlide => {
                  setCurrent(currentSlide)
                }}
              >
                {slides.map((e, i) => (
                  <div
                    key={i}
                    className={classes.slideItem}
                    onClick={() => {
                      setIsDefaultSlide(false)
                      sliderRef?.current?.slickGoTo(i, false)
                    }}
                  >
                    <div
                      className={classes.slideItemImg}
                      style={{
                        height: 245,
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${e.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className={classes.slideItemOverlay}></div>
                      {e.icon && (
                        <div className={classes.transition}>
                          <Image src={e.icon} width={52} height={35} alt={e.title} />
                        </div>
                      )}
                      <span className={clsx(classes.transition, classes.slideItemTitle)}>{e.title}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  )
}
