import { MantineTheme, createStyles } from "@mantine/core"

export const useGeneralStyles = createStyles((theme: MantineTheme) => ({
  boxShadow: {
    md: {
      boxShadow: "1rem 1rem 1rem rgba(0, 0, 0, 0.4)",
    },
  },
  paddingBlock700: {
    paddingBlock: theme.other?.size[700],

    [theme.fn.smallerThan("sm")]: {
      paddingBlock: theme.other?.size[400],
    },
  },
  paddingTop900: {
    paddingTop: theme.other?.size[900],

    [theme.fn.smallerThan("sm")]: {
      paddingTop: theme.other?.size[700],
    },
  },
  paddingBottom700: {
    paddingBottom: theme.other?.size[700],

    [theme.fn.smallerThan("sm")]: {
      paddingBottom: theme.other?.size[400],
    },
  },
}))
