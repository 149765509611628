import { ComponentBlocksMultipleImages } from "@/gql/generated-types"
import { Container, Flex, Grid, MantineTheme, Text, createStyles } from "@mantine/core"
import { useTranslation } from "next-i18next"
import { Settings } from "react-slick"

const useStyles = createStyles((theme: MantineTheme) => ({
  slider: {
    width: "80%",
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  col: {
    padding: "1rem 1rem",
    textAlign: "center",
    justifyItems: "center",
    alignItems: "center",
    minWidth: "140px",
    maxHeight: "140px",
  },
  item: {
    height: "140px",
    padding: "1.5rem 1.5rem",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 8,
    transition: "all .5s ease",
    textAlign: "center",
    justifyContent: "center",
    "& img": {
      maxWidth: "100%",
      height: "100%",
    },
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 0 20px rgba(0,0,0,.27)",
      borderRadius: 8,
      backgroundColor: "#fff",
    },
  },
  imageWrapper: {
    height: "3.75rem",
  },
}))

const sliderSettings: Settings = {
  infinite: true,
  // slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  touchThreshold: 1000,
  slidesToShow: 6,
  dotsClass: "slick-dots custom-slider-dots",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
  ],
}

interface Props {
  data?: ComponentBlocksMultipleImages
}

export const Partner = ({ data }: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <Container p={0} m={0} fluid mb={60}>
      <Flex p={0} m={0} direction={"column"} align={"center"} justify={"center"}>
        <Text fz={{ base: 18, sm: 24 }} fw={700} sx={{ textTransform: "uppercase" }} mt={{ base: "1rem" }}>
          {t("Partner")}
        </Text>
        <Grid columns={24} w={"100%"} p={0} m={0} mt={30} justify="space-around" align="center">
          {/* <Slider {...sliderSettings} className={classes.slider}> */}
          <Flex className={classes.slider} sx={{ width: "100%" }} w={{ md: "80%", lg: "70%" }}>
            {data?.items?.map((item, key: number) => (
              <div key={key} className={classes.col}>
                <div className={classes.item}>
                  <img src={item?.image?.data?.attributes?.url ?? ""} alt="logo" style={{}} />
                </div>
              </div>
            ))}
            {/* </Slider> */}
          </Flex>
        </Grid>
      </Flex>
    </Container>
  )
}
