import slugify from "slugify"

const imageProvider = process.env.IMAGE_PROVIDER ?? ""

export const DEFAULT_DOMAIN = "https://tamdaoc.eu"
export const IMAGE_URL_PROPERTY_PATH = "data.attributes.url"
export const IMAGE_PROVIDER_PROPERTY_PATH = "data.attributes.provider"

export const getImageUrl = (url?: string, provider = "local") => {
  return url && provider === "local" ? `${imageProvider}${url ?? ""}` : url
}

export const getBlock = (data = [], blockName: string) => {
  // @ts-ignore
  return (data ?? [])?.find(e => e?.__typename === blockName)
}

export const getPostUrl = (slug = "", locale = "vi", withDomain = false) => {
  const localePattern = locale === "vi" ? "" : `/${locale}`
  const url = `${localePattern}/${slug}`
  return withDomain ? `${process.env.DOMAIN}${url}` : url
}

export const getEventUrl = (name = "", id = "", withDomain = false) => {
  const url = `/events/${slugify(name)}-${id}`
  return withDomain ? `${process.env.DOMAIN}${url}` : url
}

export const getLetakUrl = (id = "", withDomain = false) => {
  const url = `/letak/${id}`
  return withDomain ? `${process.env.DOMAIN}${url}` : url
}

export const getCategoryUrl = (slug = "") => {
  return `/category/${slug}`
}

export const getStaticPageUrl = (slug = "", withDomain = false, locale: string = "vi") => {
  const localePattern = locale === "vi" ? "" : `/${locale}`
  const url = `${localePattern}/static-page/${slug}`
  return withDomain ? `${process.env.DOMAIN}${url}` : url
}

export const getStoreUrl = (id = "", withDomain = false, locale: string) => {
  const localePattern = locale === "vi" ? "" : `/${locale}`
  const url = `${localePattern}/stores/${id}`
  return withDomain ? `${process.env.DOMAIN ?? DEFAULT_DOMAIN}${url}` : url
}
