import { Images } from "@/constants/images"
import { Container, Flex, Grid, MantineTheme, Text, createStyles } from "@mantine/core"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import Link from "next/link"
import Slider, { Settings } from "react-slick"

const useStyles = createStyles((theme: MantineTheme) => ({
  slider: {
    width: "100%",
  },
  col: {
    padding: "1rem 1rem",
    textAlign: "center",
  },
  item: {
    padding: "0.75rem 0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 8,
    transition: "all .5s ease",
    textAlign: "center",
    "&:hover": {
      // transform: "translateY(-4px)",
      // boxShadow: "rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px, rgb(24 26 32 / 8%) 0px 3px 6px",
      cursor: "pointer",
      boxShadow: "0 0 20px rgba(0,0,0,.27)",
      borderRadius: 8,
      backgroundColor: "#fff",
    },
  },
  imageWrapper: {
    height: "3.75rem",
  },
}))

const sliderSettings: Settings = {
  infinite: true,
  // slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  touchThreshold: 1000,
  slidesToShow: 5,
  dotsClass: "slick-dots custom-slider-dots",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
  ],
}

interface Props {
  data: any
}

export const Member = (props: Props) => {
  const defaultMembers = [
    { image: Images.memberFoods, title: "TAMDA FOODS", url: "#", target: "_self" },
    { image: Images.memberOc, title: "TAMDA OC", url: "#", target: "_self" },
    { image: Images.memberExpress, title: "TAMDA EXPRESS", url: "#", target: "_self" },
    { image: Images.memberMedia, title: "TAMDA MEDIA", url: "#", target: "_self" },
    { image: Images.memberImpex, title: "TAMDA IMPEX", url: "#", target: "_self" },
  ]

  const members =
    props?.data?.companies?.map((e: any) => ({
      image: e?.image?.data?.attributes?.url,
      title: e?.name,
      target: e?.target ?? "_self",
      url: e?.url ?? "#",
      width: e?.image?.data?.attributes?.width,
      height: e?.image?.data?.attributes?.height,
    })) ?? defaultMembers

  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <Container p={0} m={0} fluid mb={60}>
      <Flex p={0} m={0} direction={"column"} align={"center"} justify={"center"}>
        <Text fz={24} fw={800} sx={{ textTransform: "uppercase" }}>
          {props?.data?.title ?? t("Member Companies", "Member Companies")}
        </Text>
        <Grid columns={24} w={"100%"} p={0} m={0} mt={30} justify="space-around" align="center">
          <Slider {...sliderSettings} className={classes.slider}>
            {members.map((e: any) => (
              <div key={e?.url} className={classes.col}>
                <Link href={e?.url} scroll={false} target={e?.target}>
                  <div className={classes.item}>
                    {e?.image && (
                      <Image
                        src={e?.image}
                        // @ts-ignore
                        width={e?.width!}
                        // @ts-ignore
                        height={e?.height!}
                        alt="logo"
                        style={{ maxWidth: "100%", height: "100%", margin: "0 auto" }}
                      />
                    )}
                    <Text fw={600} fz={16} c={"#333"} opacity={0.9}>
                      {e.title}
                    </Text>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </Grid>
      </Flex>
    </Container>
  )
}
