import { ComponentBlocksMultipleImages } from "@/gql/generated-types"
import { Container, Flex, Grid, MantineTheme, Text, createStyles } from "@mantine/core"
import { useTranslation } from "next-i18next"
import Slider, { Settings } from "react-slick"

const useStyles = createStyles((theme: MantineTheme) => ({
  slider: {
    width: "100%",
  },
  col: {
    margin: ".5rem 0",
    padding: "0 5px",
    textAlign: "center",
    height: 170,
  },
  item: {
    height: "100%",
    // height: "120px",
    padding: "0.75rem 0",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: 8,
    transition: "all .5s ease",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0 0 10px rgba(0,0,0,.17)",
      borderRadius: 8,
      backgroundColor: "#fff",
    },
  },
  imageWrapper: {
    height: "3.75rem",
  },
}))

const sliderSettings: Settings = {
  infinite: true,
  // slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  swipeToSlide: true,
  dots: false,
  arrows: false,
  touchThreshold: 1000,
  slidesToShow: 5,
  dotsClass: "slick-dots custom-slider-dots",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
  ],
}

interface Props {
  data?: ComponentBlocksMultipleImages
}

export const WhyChooseMe = ({ data }: Props) => {
  const { classes } = useStyles()
  const { t } = useTranslation("common")

  return (
    <Container p={0} m={0} fluid mb={60}>
      <Flex p={0} m={0} direction={"column"} align={"center"} justify={"center"}>
        <Text fz={{ base: 18, sm: 24 }} align="center" fw={700} lh={"1.4"} sx={{ textTransform: "uppercase" }}>
          {t("Why Choose Me")}
        </Text>
        <Grid gutter={10} columns={50} w={"100%"} mt={30} justify="space-around" align="center">
          <Slider {...sliderSettings} className={classes.slider}>
            {data?.items?.map((item, key: number) => (
              <div key={key} className={classes.col}>
                <Flex bg={"#F7F8FA"} m={0} className={classes.item}>
                  <img
                    src={item?.image?.data?.attributes?.url ?? ""}
                    width={50}
                    alt={item?.name ?? ""}
                    style={{ maxWidth: "100%", margin: "16px auto 0px" }}
                  />
                  <Text
                    fw={500}
                    p={{ base: "0 1rem", sm: "0 2.75rem" }}
                    fz={{ base: 14, sm: 16 }}
                    lh={{ base: 1.2, sm: 1.4 }}
                    opacity={0.9}
                    mt={{ base: 8, sm: 18 }}
                  >
                    {item?.name}
                  </Text>
                </Flex>
              </div>
            ))}
          </Slider>
        </Grid>
      </Flex>
    </Container>
  )
}
