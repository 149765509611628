import { getImageUrl } from "@/data/helpers"
import { ComponentBlocksSlideWith2Cta } from "@/gql/generated-types"
import { Carousel } from "@mantine/carousel"
import { Button, Center, Flex, MantineTheme, Text, createStyles } from "@mantine/core"
import { take } from "lodash"
import Link from "next/link"
import { FC } from "react"

const useStyles = createStyles((theme: MantineTheme) => ({
  carouselHome: {
    "& > .mantine-Carousel-indicators": {
      bottom: 55,
      "& > button": {
        height: 8,
        width: 26,
      },
      '& > button[data-active="true"]': {
        background: theme.colors[theme.primaryColor][6],
        width: 52,
      },
    },
    "& > .mantine-Carousel-controls": {
      display: "none",
    },
  },
  item: {
    // backgroundImage: `url(${url})`,
    height: 690,
    width: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    position: "relative",
    [theme.fn.smallerThan("sm")]: {
      height: 438,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      background: "rgba(0,0,0,.2)",
      zIndex: 0,
    },
  },
  title: {
    paddingTop: "3rem",
    fontWeight: 800,
    fontSize: 64,
    color: "#FFFFFF",
    marginBottom: 20,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 26,
    },
  },
  btn1: {
    // height: 36,
  },
  btn2: {
    // height: 36,
    // border: "1px solid #FFFFFF",
    // color: "#FFFFFF",
    borderColor: "#FFF",
    // borderWidth: "1px",
    color: "#fff",
  },
}))

interface Props {
  slides: ComponentBlocksSlideWith2Cta[]
}

export const CarouselHome: FC<Props> = ({ slides }) => {
  const { classes } = useStyles()

  return (
    <Carousel withIndicators className={classes.carouselHome}>
      {take(slides, 1).map(e => (
        <Carousel.Slide key={e.id}>
          <Center
            className={classes.item}
            sx={{
              backgroundImage: `url(${
                getImageUrl(e?.image?.data?.attributes?.url, e.image?.data?.attributes?.provider) ??
                "/images/content/tdoc-1.jpg"
              })`,
            }}
          >
            <Flex direction={"column"} sx={{ zIndex: 1 }}>
              <Text className={classes.title} transform="uppercase" align="center" lh={"1.5"}>
                {e?.title ?? "Tamda Obchodni Centrum"}
              </Text>
              <Flex justify={"center"} gap={37}>
                {e.buttonText1 && (
                  <Button className={classes.btn1} variant="filled" size="md">
                    <Link href={e.buttonUrl1 ?? "/"}>
                      <Text fw={600} fz={14} c={"#FFFFFF"}>
                        {e.buttonText1}
                      </Text>
                    </Link>
                  </Button>
                )}
                {e.buttonText2 && (
                  <Button className={classes.btn2} variant="outline" size="md">
                    <Link href={e.buttonUrl1 ?? "#"}>
                      <Text c={"#FFFFFF"}>{e.buttonText2}</Text>
                    </Link>
                  </Button>
                )}
              </Flex>
            </Flex>
          </Center>
        </Carousel.Slide>
      ))}
    </Carousel>
  )
}
